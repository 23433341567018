<template>
  <div>
    <v-layout wrap justify-center pt-5>
      <v-flex xs12 v-if="storage.middleContent">
        <v-img
          height="100%"
          width="100%"
          :src="mediaURL + storage.middleContent.image"
          :lazy-src="mediaURL + storage.middleContent.image"
              :alt="storage.middleContent.image" eager
        >
        <template v-slot:placeholder>
            <ImageLoader />
          </template>
          <v-layout wrap pt-10 justify-center>
            <v-flex xs12 sm6 md5 align-self-center text-left pa-5>
              <span
                :style="{
                  'font-size':
                    $vuetify.breakpoint.name == 'xl' ? '24px' : '20px',
                }"
                style="font-family: poppinssemibold; color: black"
                >{{ storage.middleContent.title }}</span
              >
              <br />
              <br />
              <span
                :style="{
                  'font-size':
                    $vuetify.breakpoint.name == 'xl' ? '18px' : '14px',
                }"
                style="
                  font-family: poppinsregular;
                  color: black;
                  text-align: justify;
                "
                v-html="storage.middleContent.description"
              ></span>
            </v-flex>
            <v-flex xs12 sm6 md5 align-self-center text-left> </v-flex>
          </v-layout>
        </v-img>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  props: ["storage"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
    };
  },
};
</script>