var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-layout',{attrs:{"wrap":"","justify-center":"","pt-5":""}},[(_vm.storage.middleContent)?_c('v-flex',{attrs:{"xs12":""}},[_c('v-img',{attrs:{"height":"100%","width":"100%","src":_vm.mediaURL + _vm.storage.middleContent.image,"lazy-src":_vm.mediaURL + _vm.storage.middleContent.image,"alt":_vm.storage.middleContent.image,"eager":""},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('ImageLoader')]},proxy:true}],null,false,958508573)},[_c('v-layout',{attrs:{"wrap":"","pt-10":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm6":"","md5":"","align-self-center":"","text-left":"","pa-5":""}},[_c('span',{staticStyle:{"font-family":"poppinssemibold","color":"black"},style:({
                'font-size':
                  _vm.$vuetify.breakpoint.name == 'xl' ? '24px' : '20px',
              })},[_vm._v(_vm._s(_vm.storage.middleContent.title))]),_c('br'),_c('br'),_c('span',{staticStyle:{"font-family":"poppinsregular","color":"black","text-align":"justify"},style:({
                'font-size':
                  _vm.$vuetify.breakpoint.name == 'xl' ? '18px' : '14px',
              }),domProps:{"innerHTML":_vm._s(_vm.storage.middleContent.description)}})]),_c('v-flex',{attrs:{"xs12":"","sm6":"","md5":"","align-self-center":"","text-left":""}})],1)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }