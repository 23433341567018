<template>
  <div>
    <v-layout wrap justify-center style="background-color: #eefff2">
      <v-flex xs12>
        <v-layout wrap py-16 justify-center v-if="storage.caroselContent">
          <v-flex xs12>
            <span
              :style="{
                'font-size': $vuetify.breakpoint.name == 'xl' ? '24px' : '20px',
              }"
              style="font-family: poppinssemibold; color: black"
              >{{ storage.caroselContent.title }}</span
            >
          </v-flex>
          <v-flex xs12 sm10 md5 text-center pt-2>
            <span
              :style="{
                'font-size': $vuetify.breakpoint.name == 'xl' ? '18px' : '14px',
              }"
              style="font-family: poppinsregular; color: black"
              v-html="storage.caroselContent.description"
            ></span>
          </v-flex>
          <v-flex xs12 sm10 md8 text-center>
            <span
              :style="{
                'font-size': $vuetify.breakpoint.name == 'xl' ? '20px' : '16px',
              }"
              style="font-family: poppinsmedium; color: black; font-size: 16px"
              v-html="storage.caroselContent.caption"
            ></span>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center v-if="category" pb-10>
          <v-flex xs12 sm12 md10 py-md-0 pa-2 v-if="category.length > 0">
            <OwlCarousel :nav="false" :dots="true" :responsive="owlResponsive">
              <template xs12 sm10 v-for="(item, i) in category">
                <v-layout wrap justify-center :key="i">
                  <v-flex px-1 px-sm-3>
                    <v-card rounded="xl" flat height="420px">
                      <v-img
                        :src="mediaURL + item.image"
                        :lazy-src="mediaURL + item.image"
                        :alt="item.image"
                        eager
                        ><template v-slot:placeholder>
                          <ImageLoader /> </template
                      ></v-img>
                      <v-layout wrap pa-5>
                        <v-flex xs12 text-left>
                          <span
                            style="
                              font-family: poppinssemibold;
                              color: black;
                              font-size: 16px;
                            "
                            >{{ item.name }}</span
                          >
                          <br />
                          <br />
                          <span
                            style="
                              font-family: poppinsregular;
                              color: black;
                              font-size: 14px;
                            "
                          >
                            {{ item.description.slice(0, 80) }}
                          </span>
                          <span v-if="item.description.length > 80"> ... </span>
                          <br />
                          <br />
                          <v-btn
                            text
                            color="#3CB043"
                            :to="
                              '/categoryDetails?name=' +
                              item.name +
                              '&id=' +
                              item._id
                            "
                          >
                            <span
                              style="
                                font-family: poppinssemibold;
                                text-transform: none;
                              "
                              >Learn More</span
                            >
                          </v-btn>
                          <v-icon color="black">mdi-arrow-right</v-icon>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </v-layout>
              </template>
            </OwlCarousel>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["storage"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      user: {},
      category: [],
      owlResponsive: {
        0: { items: 1, nav: false },
        600: { items: 2, nav: false },
        960: { items: 3, nav: false },
        1264: { items: 4, nav: false },
        1920: { items: 6, nav: false },
      },
    };
  },
  beforeMount() {
    this.getData();
  },

  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/course/catogory/list",
        method: "GET",
        // headers: {
        //   token: localStorage.getItem("token"),
        // },
      })
        .then((response) => {
          this.appLoading = false;
          this.category = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>