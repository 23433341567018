var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-layout',{staticStyle:{"background-color":"#eefff2"},attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[(_vm.storage.caroselContent)?_c('v-layout',{attrs:{"wrap":"","py-16":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticStyle:{"font-family":"poppinssemibold","color":"black"},style:({
              'font-size': _vm.$vuetify.breakpoint.name == 'xl' ? '24px' : '20px',
            })},[_vm._v(_vm._s(_vm.storage.caroselContent.title))])]),_c('v-flex',{attrs:{"xs12":"","sm10":"","md5":"","text-center":"","pt-2":""}},[_c('span',{staticStyle:{"font-family":"poppinsregular","color":"black"},style:({
              'font-size': _vm.$vuetify.breakpoint.name == 'xl' ? '18px' : '14px',
            }),domProps:{"innerHTML":_vm._s(_vm.storage.caroselContent.description)}})]),_c('v-flex',{attrs:{"xs12":"","sm10":"","md8":"","text-center":""}},[_c('span',{staticStyle:{"font-family":"poppinsmedium","color":"black","font-size":"16px"},style:({
              'font-size': _vm.$vuetify.breakpoint.name == 'xl' ? '20px' : '16px',
            }),domProps:{"innerHTML":_vm._s(_vm.storage.caroselContent.caption)}})])],1):_vm._e(),(_vm.category)?_c('v-layout',{attrs:{"wrap":"","justify-center":"","pb-10":""}},[(_vm.category.length > 0)?_c('v-flex',{attrs:{"xs12":"","sm12":"","md10":"","py-md-0":"","pa-2":""}},[_c('OwlCarousel',{attrs:{"nav":false,"dots":true,"responsive":_vm.owlResponsive}},[_vm._l((_vm.category),function(item,i){return [_c('v-layout',{key:i,attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"px-1":"","px-sm-3":""}},[_c('v-card',{attrs:{"rounded":"xl","flat":"","height":"420px"}},[_c('v-img',{attrs:{"src":_vm.mediaURL + item.image,"lazy-src":_vm.mediaURL + item.image,"alt":item.image,"eager":""},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('ImageLoader')]},proxy:true}],null,true)}),_c('v-layout',{attrs:{"wrap":"","pa-5":""}},[_c('v-flex',{attrs:{"xs12":"","text-left":""}},[_c('span',{staticStyle:{"font-family":"poppinssemibold","color":"black","font-size":"16px"}},[_vm._v(_vm._s(item.name))]),_c('br'),_c('br'),_c('span',{staticStyle:{"font-family":"poppinsregular","color":"black","font-size":"14px"}},[_vm._v(" "+_vm._s(item.description.slice(0, 80))+" ")]),(item.description.length > 80)?_c('span',[_vm._v(" ... ")]):_vm._e(),_c('br'),_c('br'),_c('v-btn',{attrs:{"text":"","color":"#3CB043","to":'/categoryDetails?name=' +
                            item.name +
                            '&id=' +
                            item._id}},[_c('span',{staticStyle:{"font-family":"poppinssemibold","text-transform":"none"}},[_vm._v("Learn More")])]),_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-arrow-right")])],1)],1)],1)],1)],1)]})],2)],1):_vm._e()],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }