var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-img',{attrs:{"src":require("./../../assets/images/section4frame1.png")}},[_c('v-layout',{attrs:{"wrap":"","justify-center":"","py-10":""}},[(_vm.storage.bottomContent)?_c('v-flex',{attrs:{"xs12":"","sm12":"","md10":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":"","pt-10":"","px-9":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md8":"","lg9":"","align-self-center":""}},[_c('v-img',{attrs:{"src":require("./../../assets/images/section4frame2.png")}},[(_vm.storage.bottomContent)?_c('v-layout',{attrs:{"wrap":"","justify-center":"","pt-10":""}},[_c('v-flex',{attrs:{"xs10":"","sm9":"","text-left":"","align-self-center":"","pt-16":""}},[_c('span',{staticStyle:{"font-family":"poppinssemibold","color":"black"},style:({
                      'font-size':
                        _vm.$vuetify.breakpoint.name == 'xl' ? '24px' : '20px',
                    })},[_vm._v(_vm._s(_vm.storage.bottomContent.title))]),_c('br'),_c('br'),_c('span',{staticStyle:{"font-family":"poppinsregular","color":"black","text-align":"justify"},style:({
                      'font-size':
                        _vm.$vuetify.breakpoint.name == 'xl' ? '18px' : '14px',
                    }),domProps:{"innerHTML":_vm._s(_vm.storage.bottomContent.description)}}),_c('br'),(!_vm.appLogin)?_c('v-btn',{attrs:{"color":"#3CB043","dark":"","to":"/signUp"}},[_c('span',{staticStyle:{"font-family":"opensanssemibold","text-transform":"none"}},[_vm._v("Join Now")])]):_vm._e()],1)],1):_vm._e()],1)],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md4":"","lg3":"","text-right":""}},[_c('v-img',{style:({
                height:
                  _vm.$vuetify.breakpoint.name == 'xs'
                    ? '100%'
                    : _vm.$vuetify.breakpoint.name == 'sm'
                    ? '100%'
                    : _vm.$vuetify.breakpoint.name == 'md'
                    ? '100%'
                    : _vm.$vuetify.breakpoint.name == 'lg'
                    ? '100%'
                    : '100%',
              }),attrs:{"src":_vm.mediaURL + _vm.storage.bottomContent.image,"lazy-src":_vm.mediaURL + _vm.storage.bottomContent.image,"alt":_vm.storage.bottomContent.image,"eager":""},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('ImageLoader')]},proxy:true}],null,false,958508573)})],1)],1)],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }