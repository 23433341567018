<template>
  <div>
    <v-img src="./../../assets/images/section4frame1.png">
      <v-layout wrap justify-center py-10>
        <v-flex xs12 sm12 md10 v-if="storage.bottomContent">
          <v-layout wrap justify-center pt-10 px-9>
            <v-flex xs12 sm12 md8 lg9 align-self-center>
              <v-img src="./../../assets/images/section4frame2.png">
                <v-layout
                  wrap
                  justify-center
                  pt-10
                  v-if="storage.bottomContent"
                >
                  <v-flex xs10 sm9 text-left align-self-center pt-16>
                    <span
                      :style="{
                        'font-size':
                          $vuetify.breakpoint.name == 'xl' ? '24px' : '20px',
                      }"
                      style="font-family: poppinssemibold; color: black"
                      >{{ storage.bottomContent.title }}</span
                    >
                    <br />
                    <br />
                    <span
                      :style="{
                        'font-size':
                          $vuetify.breakpoint.name == 'xl' ? '18px' : '14px',
                      }"
                      style="
                        font-family: poppinsregular;
                        color: black;
                        text-align: justify;
                      "
                      v-html="storage.bottomContent.description"
                    ></span>
                    <br />
                    <v-btn color="#3CB043" v-if="!appLogin" dark to="/signUp"
                      ><span
                        style="
                          font-family: opensanssemibold;
                          text-transform: none;
                        "
                        >Join Now</span
                      ></v-btn
                    >
                  </v-flex>
                </v-layout>
              </v-img>
            </v-flex>
            <v-flex xs12 sm12 md4 lg3 text-right>
              <v-img
                :style="{
                  height:
                    $vuetify.breakpoint.name == 'xs'
                      ? '100%'
                      : $vuetify.breakpoint.name == 'sm'
                      ? '100%'
                      : $vuetify.breakpoint.name == 'md'
                      ? '100%'
                      : $vuetify.breakpoint.name == 'lg'
                      ? '100%'
                      : '100%',
                }"
                :src="mediaURL + storage.bottomContent.image"
                :lazy-src="mediaURL + storage.bottomContent.image"
              :alt="storage.bottomContent.image" eager
              ><template v-slot:placeholder>
            <ImageLoader />
          </template></v-img>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-img>
  </div>
</template>
<script>
export default {
  props: ["storage"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
    };
  },
  computed: {
    appUser() {
      return this.$store.state.userData;
    },
    appLogin() {
      return this.$store.state.isLoggedIn;
    },
  },
};
</script>