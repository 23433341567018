<template>
  <div>
    <v-layout wrap justify-center>
      <v-flex xs12 v-if="storage.topContent">
        <v-layout wrap pt-10 justify-center>
          <v-flex xs10 sm6 md4 lg3>
            <v-img :src="mediaURL + storage.topContent.image" :lazy-src="mediaURL + storage.topContent.image"
              :alt="storage.topContent.image" eager><template v-slot:placeholder>
            <ImageLoader />
          </template></v-img>
          </v-flex>
          <v-flex xs12 sm12 md8 lg6 align-self-center text-left pa-10 pt-16>
            <span :style="{
              'font-size': $vuetify.breakpoint.name == 'xl' ? '24px' : '20px',
            }" style="
                font-family: poppinssemibold;
                color: black;
              ">{{ storage.topContent.title }}</span>
            <br />
            <br />
            <span :style="{
              'font-size': $vuetify.breakpoint.name == 'xl' ? '18px' : '14px',
            }" style="
                font-family: poppinsregular;
                color: black;
                text-align: justify;
              " v-html="storage.topContent.description"></span>
          </v-flex>
          <v-flex xs1 text-right pl-16 class="hidden-sm-and-down">
            <v-layout wrap justify-end>
              <v-flex xs12 text-right>
                <v-img height="100%" width="100px" src="./../../assets/images/gp1.png"></v-img>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  props: ["storage"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
    };
  },
};
</script>