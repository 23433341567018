<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-layout wrap justify-center>
      <v-flex xs12>
        <!-- <v-img src="./../../assets/Background.png"> -->
        <v-carousel
          v-model="slideNo"
          continuous
          cycle
          :interval="slideInterval"
          :show-arrows="false"
          hide-delimiters
          :height="sliderHeight" 
        >
          <!-- :height="sliderHeight" -->
          <v-carousel-item v-for="(item, i) in home.bannerImage" :key="i">
            <v-card flat>
              <v-img
                :lazy-src="mediaURL + item"
                :src="mediaURL + item"
                width="100%"
                contain
                :alt="item"
                eager
                ><template v-slot:placeholder> <ImageLoader /> </template>
                <v-layout wrap fill-height align-content-center>
                  <v-flex xs12 align-self-center>
                    <v-layout wrap justify-center>
                      <v-flex xs12>
                        <span
                          :style="{
                            'font-size':
                              $vuetify.breakpoint.name == 'xl'
                                ? '24px'
                                : $vuetify.breakpoint.name == 'xs'
                                ? '13px'
                                : $vuetify.breakpoint.name == 'sm'
                                ? '16px'
                                : '20px',
                          }"
                          style="
                            font-family: poppinsmedium;
                            color: white;
                            text-transform: uppercase;
                            letter-spacing: 1px;
                          "
                          >{{ home.mainTitle }}</span
                        >
                      </v-flex>
                      <v-flex xs12 pt-xs-0 pt-sm-0 pt-md-5 pt-lg-5 pt-xl-5>
                        <span
                          :style="{
                            'font-size':
                              $vuetify.breakpoint.name == 'xl'
                                ? '32px'
                                : $vuetify.breakpoint.name == 'xs'
                                ? '16px'
                                : $vuetify.breakpoint.name == 'sm'
                                ? '20px'
                                : '28px',
                          }"
                          style="
                            font-family: poppinsbold;
                            color: white;
                            letter-spacing: 1px;
                          "
                        >
                          {{ home.subTitle }}
                        </span>
                      </v-flex>
                      <v-flex xs12 sm6 md5 pt-xs-0 pt-sm-0 pt-md-4 pt-lg-4 pt-xl-4>
                        <span
                          :style="{
                            'font-size':
                              $vuetify.breakpoint.name == 'xl'
                                ? '20px'
                                : $vuetify.breakpoint.name == 'xs'
                                ? '10px'
                                : $vuetify.breakpoint.name == 'sm'
                                ? '13px'
                                : '16px',
                          }"
                          style="
                            font-family: poppinsregular;
                            color: white;
                            letter-spacing: 1px;
                          "
                          v-html="home.description"
                        >
                        </span>
                      </v-flex>
                      <v-flex xs12 sm12 md12 pt-xs-0 pt-sm-0 pt-md-4 pt-lg-4 pt-xl-4>
                        <v-btn color="#3CB043" dark :to="'/about'" class="hidden-sm-and-down"
                          ><span
                            style="
                              font-family: opensanssemibold;
                              text-transform: none;
                            "
                            >Learn More</span
                          ></v-btn
                        >
                        <v-btn color="#3CB043" dark :to="'/about'" small class="hidden-md-and-up"
                          ><span
                            style="
                              font-family: opensanssemibold;
                              text-transform: none;
                            "
                            >Learn More</span
                          ></v-btn
                        >
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-img>
            </v-card>
          </v-carousel-item>
        </v-carousel>
        <!-- </v-img> -->
        <Section1 :storage="home" />
        <Section2 :storage="home" />
        <Section3 :storage="home" />
        <Section4 :storage="home" />
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import Section1 from "./section1";
import Section2 from "./section2";
import Section3 from "./section3";
import Section4 from "./section4";
export default {
  components: {
    Section1,
    Section2,
    Section3,
    Section4,
  },
  data() {
    return {
      appLoading: false,
      ServerError: false,
      user: {},
      home: [],
      fab: true,
      slideNo: 0,
      slideInterval: 4000,
    };
  },
  computed: {
    sliderHeight() {
      var height = "100%";
      if (this.$vuetify.breakpoint.xl) height = "850px";
      if (this.$vuetify.breakpoint.lg) height = "600px";
      if (this.$vuetify.breakpoint.md) height = "550px";
      if (this.$vuetify.breakpoint.sm) height = "280px";
      if (this.$vuetify.breakpoint.xs) height = "200px";
      return height;
    },
  },
  beforeMount() {
    this.getData();
  },

  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/home/admin/getDetails",
        method: "GET",
        // headers: {
        //   token: localStorage.getItem("token"),
        // },
      })
        .then((response) => {
          this.appLoading = false;
          this.home = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>