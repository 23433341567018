var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('PageLoader',{attrs:{"storage":_vm.appLoading}}),(_vm.ServerError)?_c('ServerError'):_vm._e(),_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-carousel',{attrs:{"continuous":"","cycle":"","interval":_vm.slideInterval,"show-arrows":false,"hide-delimiters":"","height":_vm.sliderHeight},model:{value:(_vm.slideNo),callback:function ($$v) {_vm.slideNo=$$v},expression:"slideNo"}},_vm._l((_vm.home.bannerImage),function(item,i){return _c('v-carousel-item',{key:i},[_c('v-card',{attrs:{"flat":""}},[_c('v-img',{attrs:{"lazy-src":_vm.mediaURL + item,"src":_vm.mediaURL + item,"width":"100%","contain":"","alt":item,"eager":""},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('ImageLoader')]},proxy:true}],null,true)},[_c('v-layout',{attrs:{"wrap":"","fill-height":"","align-content-center":""}},[_c('v-flex',{attrs:{"xs12":"","align-self-center":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticStyle:{"font-family":"poppinsmedium","color":"white","text-transform":"uppercase","letter-spacing":"1px"},style:({
                          'font-size':
                            _vm.$vuetify.breakpoint.name == 'xl'
                              ? '24px'
                              : _vm.$vuetify.breakpoint.name == 'xs'
                              ? '13px'
                              : _vm.$vuetify.breakpoint.name == 'sm'
                              ? '16px'
                              : '20px',
                        })},[_vm._v(_vm._s(_vm.home.mainTitle))])]),_c('v-flex',{attrs:{"xs12":"","pt-xs-0":"","pt-sm-0":"","pt-md-5":"","pt-lg-5":"","pt-xl-5":""}},[_c('span',{staticStyle:{"font-family":"poppinsbold","color":"white","letter-spacing":"1px"},style:({
                          'font-size':
                            _vm.$vuetify.breakpoint.name == 'xl'
                              ? '32px'
                              : _vm.$vuetify.breakpoint.name == 'xs'
                              ? '16px'
                              : _vm.$vuetify.breakpoint.name == 'sm'
                              ? '20px'
                              : '28px',
                        })},[_vm._v(" "+_vm._s(_vm.home.subTitle)+" ")])]),_c('v-flex',{attrs:{"xs12":"","sm6":"","md5":"","pt-xs-0":"","pt-sm-0":"","pt-md-4":"","pt-lg-4":"","pt-xl-4":""}},[_c('span',{staticStyle:{"font-family":"poppinsregular","color":"white","letter-spacing":"1px"},style:({
                          'font-size':
                            _vm.$vuetify.breakpoint.name == 'xl'
                              ? '20px'
                              : _vm.$vuetify.breakpoint.name == 'xs'
                              ? '10px'
                              : _vm.$vuetify.breakpoint.name == 'sm'
                              ? '13px'
                              : '16px',
                        }),domProps:{"innerHTML":_vm._s(_vm.home.description)}})]),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","pt-xs-0":"","pt-sm-0":"","pt-md-4":"","pt-lg-4":"","pt-xl-4":""}},[_c('v-btn',{staticClass:"hidden-sm-and-down",attrs:{"color":"#3CB043","dark":"","to":'/about'}},[_c('span',{staticStyle:{"font-family":"opensanssemibold","text-transform":"none"}},[_vm._v("Learn More")])]),_c('v-btn',{staticClass:"hidden-md-and-up",attrs:{"color":"#3CB043","dark":"","to":'/about',"small":""}},[_c('span',{staticStyle:{"font-family":"opensanssemibold","text-transform":"none"}},[_vm._v("Learn More")])])],1)],1)],1)],1)],1)],1)],1)}),1),_c('Section1',{attrs:{"storage":_vm.home}}),_c('Section2',{attrs:{"storage":_vm.home}}),_c('Section3',{attrs:{"storage":_vm.home}}),_c('Section4',{attrs:{"storage":_vm.home}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }